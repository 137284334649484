import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const codedImages = [
    require('./../../images/logo/logo.png')
];

const bnr1 = require('./../../images/background/bg-6.png');

const About = ({imagesData}) => {

    // const [imagesData, setImagesData] = useState(codedImages);
    const [aboutImages, setAboutImages] = useState(codedImages);

    useEffect(() => {
        function loadScript(src) {
            return new Promise((resolve, reject) => {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', () => resolve());
                script.addEventListener('error', (e) => reject(e));
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        }

        loadScript('./assets/js/masonary.js');

        // Check and log imagesData
        console.log("imagesData:", imagesData);

        if (imagesData) {
            try {
                const imagesD = imagesData;
                console.log("Parsed imagesData:", imagesD);
                setAboutImages(imagesD);
            } catch (error) {
                console.error("Failed to parse imagesData:", error);
            }
        }
    }, [imagesData]);


    const options = {
        loop: true,
        autoplay: true,
        margin: 30,
        nav: false,
        dots: true,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive: {
            0: {
                items: 1,
            },
            991: {
                items: 1,
            },
        },
    };

    console.log("imagesData:", imagesData);

    return (
        <>
            <div
                className="section-full mobile-page-padding p-t80 p-b30 bg-dark bg-repeat square_shape2 bg-moving"
                style={{ backgroundImage: `url(${bnr1})` }}
            >
                <div className="container">
                    {/* TITLE START */}
                    <div className="section-head">
                        <div className="mt-separator-outer separator-center">
                            <div className="mt-separator">
                                <h2 className="text-white text-uppercase sep-line-one ">
                                    <span className="font-weight-300 text-primary">About</span> Company
                                </h2>
                            </div>
                        </div>
                    </div>
                    {/* TITLE END */}
                    <div className="section-content">
                        <div className="row">
                            <div className="col-md-9 col-sm-12">
                                <OwlCarousel className="owl-carousel about-home owl-btn-vertical-center" {...options}>

                                {/* {codedImages.map((item, index) => (
                                        <div className="item" key={index}>
                                            <div className="mt-img-effect zoom-slow">
                                                <NavLink to="/about">
                                                    <img className="about-section-img" src={item} alt="" />
                                                </NavLink>
                                            </div>
                                        </div>
                                    ))} */}
                                {imagesData.length > 0 ? (
                                    imagesData.map((image, index) => (
                                    <div className="item" key={index}>
                                        <div className="mt-img-effect zoom-slow">
                                            <NavLink to="/about">
                                                <img className="about-section-img"
                                                // src={image}
                                                src={ `${process.env.REACT_APP_API_URL}/storage/${image}` }

                                                alt=""

                                                />
                                            </NavLink>
                                        </div>
                                    </div>
                                ))) : (
                                     codedImages.map((item, index) => (
                                        <div className="item" key={index}>
                                            <div className="mt-img-effect zoom-slow">
                                                <NavLink to="/about">
                                                    <img className="about-section-img" src={item} alt="" />
                                                </NavLink>
                                            </div>
                                        </div>
                                    ))
                                )

                                }

                                </OwlCarousel>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <div className="about-home-right bg-white p-a30">
                                    <h3 className="m-t0">
                                        <span className="font-weight-100">Engineering Excellence</span>, Delivering Trust
                                    </h3>
                                    <p>
                                        <strong>
                                            Laffo Engineering PLC was established in 2014 with a vision to support national development and contribute to the growth of various sectors.
                                        </strong>
                                    </p>
                                    <p>
                                        With an initial capital of Birr 1,130,000.00, we have positioned ourselves as a leading supplier of essential materials and equipment in Ethiopia.
                                    </p>
                                    <div className="text-right">
                                        <NavLink to="/about" className="site-button-link" data-hover="Read More">
                                            Learn More <i className="fa fa-angle-right arrow-animation" />
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hilite-title text-left p-l50 text-uppercase hilite-dark">
                    <strong>About</strong>
                </div>
            </div>
        </>
    );
};

export default About;
