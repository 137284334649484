import React,{useState,useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import Switcher from '../Elements/Switcher';
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { FaMapLocationDot } from 'react-icons/fa6';
import axios from 'axios';
import { FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

var bgimage = require('./../../images/background/bg-site.png');

const updateFooterLogo =  require('./../../images/logo/logo-light.png');
const Footer = () => {
   
    const [about, setAbout] = useState({})

    useEffect(() => {
        const fetchAbout = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/business-setup`);
            setAbout(response.data);
            console.log("Business Setup : ",response.data);
        };
        fetchAbout();
    }, []);

        return (
            <>
                <footer className="site-footer footer-large  footer-dark	footer-wide">
                    {/* FOOTER BLOCKES START */}
                    <div className="footer-top overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                {/* ABOUT COMPANY */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_about">
                                        {/*<h4 class="widget-title">About Company</h4>*/}
                                        <div className="logo-footer clearfix p-b30 " style={{display:'flex', alignItems:'center', gap:'5px'}}>
                                            <NavLink to={"./"}>
                                                <img src={updateFooterLogo} style={{height:'100px'}} alt="" />
                                            </NavLink>
                                           
                                        </div>
                                        <p className="max-w400 p-t15">
                                            Laffo Engineering was founded in 2014 as a response to Ethiopia’s growing demand for reliable suppliers in key sectors.                                        
                                        </p>
                                        <ul className="social-icons  mt-social-links">
                                            {
                                                about && about.telegramLink &&
                                                <li>
                                                    <a style={{
                                                    }} href={about.telegramLink} title='Telegram'>
                                                        <i className="fa fa-telegram" ></i>
                                                    </a>
                                                </li>
                                            }

                                            {
                                                about && about.facebookLink &&
                                                <li>
                                                    <a style={{
                                                    }} href={about.facebookLink} title='Facebook'>
                                                        <i className="fa fa-facebook" ></i>
                                                    </a>
                                                </li>
                                            }

                                            {
                                                about && about.linkedInLink &&
                                                <li>
                                                    <a style={{
                                                    }} href={about.linkedInLink} title='LinkedIn'>
                                                        <i className="fa fa-linkedin" ></i>
                                                    </a>
                                                </li>
                                            }

                                            {
                                                about && about.instagramLink &&
                                                <li>
                                                    <a style={{
                                                    }} href={about.instagramLink} title='Instagram'>
                                                        <i className="fa fa-instagram" ></i>
                                                    </a>
                                                </li>
                                            }

                                            {
                                                about && about.twitterLink &&
                                                <li>
                                                    <a style={{
                                                    }} href={about.twitterLink} title='Twitter'>
                                                        <FaXTwitter />
                                                    </a>
                                                </li>
                                            }

                                            {
                                                about && about.tiktokLink &&
                                                <li>
                                                    <a style={{
                                                    }} href={about.tiktokLink} title='Tiktok'>
                                                        <FaTiktok />
                                                    </a>
                                                </li>
                                            }
                                          
                                        </ul>
                                    </div>
                                </div>
                                {/* RESENT POST */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_address_outer">
                                        <h4 className="widget-title">Contact Us</h4>
                                        <ul className="contactUsCustom">
                                            <div
                                              className='contactUsCustomList'
                                            >
                                                {/* <FaEnvelope />
                                                <a  href='mailto:laffoengineering12@gmail.com'>
                                                    laffoengineering12@gmail.com
                                                </a> */}

                                                {
                                                    about && about.email &&
                                                    <>
                                                        <FaEnvelope />
                                                        <a  href={`mailto:${about.email}`}>
                                                            {about.email}
                                                        </a>
                                                    </>
                                                }

                                                {/* <FaEnvelope />
                                                <a  href='mailto:laffoengineering12@gmail.com'>
                                                    laffoengineering12@gmail.com
                                                </a> */}
                                            </div>

                                            <div
                                              className='contactUsCustomList'
                                            >

                                            
                                                {
                                                    about && about.phone1 &&
                                                    <>
                                                    <FaPhoneAlt />
                                                        <a  href={`mailto:${about.phone1}`}>
                                                            {about.phone1}
                                                        </a>
                                                    </>
                                                }
                                                
                                                {/* <a  href='to:+251 944 257 225'>
                                                  +251 944 257 225
                                                </a> */}
                                            </div>

                                            <div
                                              className='contactUsCustomList'
                                            >

                                                {
                                                    about && about.phone2 &&
                                                    <>
                                                    <FaPhoneAlt />
                                                        <a  href={`mailto:${about.phone2}`}>
                                                            {about.phone2}
                                                        </a>
                                                    </>
                                                }
                                                {/* <FaPhoneAlt />
                                                <a  href='to:+251 116 395 250'>
                                                  +251 116 395 250
                                                </a> */}
                                            </div>

                                            <div
                                              className='contactUsCustomList'
                                            >
                                                <FaPhoneAlt />
                                                <a  href='to: +251 911 168 287'>
                                                   +251 911 168 287
                                                </a>
                                            </div>
                                           
                                        </ul>
                                    </div>
                                </div>
                                {/* USEFUL LINKS */}
                                <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                                    <div className="widget widget_services inline-links">
                                        <h4 className="widget-title">Useful links</h4>
                                        <ul className='contactUsCustom'>
                                           <NavLink to={"/about"}>About</NavLink>
                                           <NavLink to={"/products"}>Products</NavLink>
                                           <NavLink to={"/services"}>Services</NavLink>
                                           <NavLink to={"/contactus"}>Contact Us</NavLink>
                                        </ul>
                                    </div>
                                </div>
                                {/* TAGS */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_address_outer">
                                        <h4 className="widget-title">Address</h4>
                                        <ul className="widget_address">
                                            
                                            <div
                                              className='contactUsCustomListAddress'
                                            >
                                                <span
                                                 className='icon'
                                                    >
                                                     1
                                                </span>
                                                <span >
                                                 Bole Imperial, Wereda 14, Alfoz Plaza Building, Addis Ababa
                                                </span>
                                            </div>


                                            <div
                                              className='contactUsCustomListAddress'
                                            >
                                                <span
                                                 className='icon'
                                                    >
                                                     2
                                                </span>
                                                <span>
                                                     Wereda 13, Awi-Hakim Building, Addis Ababa     
                                                </span>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                                {/* NEWSLETTER */}
                            </div>
                        </div>
                    </div>
                    {/* FOOTER COPYRIGHT */}
                    <div className="footer-bottom overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                <div className="mt-footer-bot-center">
                                    <span className="copyrights-text">© 2024 Laffo Engineering. Powered By
                                    {' '}
                                        <a href='https://keradiondesigns.com'
                                         style={{
                                             color: '#fff'
                                         }}
                                        >
                                             Keradion Technologies
                                        </a>
                                    .</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)}/> */}

            </>
        );
    
};

export default Footer;