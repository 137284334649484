import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var img1 = require('./../../images/video-bg.jpg');

const OurValue  = ({about}) => {

    const [aboutData, setAboutData] = useState(null);
    useEffect(() => {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

        if(about){
            console.log("About Info : ",about);
            setAboutData(about);
        }

    }, [])

        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b30 bg-white">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-left">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Laffo</span> Engineering</h2>
                                            </div>
                                            <p>
                                                {
                                                    about.values?
                                                        about.values
                                                            :
                                                        `Our values are rooted in delivering the highest quality products and services while prioritizing our clients' needs through tailored solutions and exceptional service. We uphold integrity, embrace innovation, and are committed to sustainability, ensuring that we operate with honesty and responsibility in all aspects of our business.`
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="author-info p-t15">
                                        <div className="author-signature">
                                            <img src={require('./../../../src/images/logo/logo.png')} alt="Laffo Engineering" width={100} />
                                        </div>
                                        <div className="author-name">
                                            <h4 className="m-t0">Laffo Engineering</h4>

                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="mt-count m-b30 text-white mt-icon-box-wraper center  bg-dark p-a20">
                                        <div className="counter font-30 font-weight-800 m-b15 text-primary"><CountUp end={about && about.product_types ? about.product_types: 500} duration={5} /> +</div>
                                        <h4 className="m-tb0">Products Type</h4>
                                    </div>
                                    <div className="mt-count m-b30 text-white mt-icon-box-wraper center  bg-dark p-a20">
                                        <div className="counter font-30 font-weight-800 m-b15  text-primary"><CountUp end={about && about.satisfaction_rate ? about.satisfaction_rate:95} duration={5} /> %</div>
                                        <h4 className="m-tb0">Client Satisfaction Rate</h4>
                                    </div>
                                    <div className="mt-count m-b30 text-white mt-icon-box-wraper center  bg-dark p-a20">
                                        <div className="counter font-30 font-weight-800 m-b15 text-primary"><CountUp end={about && about.categories ? about.categories : 9} duration={5} /> +</div>
                                        <h4 className="m-tb0">Service Categories</h4>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-6">
                                    <div className="p-b0">
                                        <div className="mt-box">
                                            <h3 className="m-t0"><span className="font-weight-100"> We have more than</span><span className="text-primary">
                                             {' '}{about.experience ? about.experience : 10 } {' '}
                                             years</span> experience in providing</h3>
                                        </div>
                                        <span className="progressText text-black"><b>Laboratory Equipment</b></span>
                                        <div className="progress mt-probar-1  m-b15 m-t5">
                                            <div className="progress-bar bg-primary " aria-valuenow={100} aria-valuemin={100} aria-valuemax={100}>
                                                {/* <span className="popOver" data-toggle="tooltips" data-placement="top" title="100%" /> */}
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Medical Devices</b></span>
                                        <div className="progress mt-probar-1 m-b15 m-t5">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={100} aria-valuemin={10} aria-valuemax={100}>
                                                {/* <span className="popOver" data-toggle="tooltips" data-placement="top" title="100%" /> */}
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Construction Materials</b></span>
                                        <div className="progress mt-probar-1 m-b15 m-t5">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>
                                                {/* <span className="popOver" data-toggle="tooltips" data-placement="top" title="100%" /> */}
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Industrial Materials</b></span>
                                        <div className="progress mt-probar-1 m-b15 m-t5">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>
                                                {/* <span className="popOver" data-toggle="tooltips" data-placement="top" title="100%" /> */}
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Plastic Raw Materials & Chemicals</b></span>
                                        <div className="progress mt-probar-1 m-b15 m-t5">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>
                                                {/* <span className="popOver" data-toggle="tooltips" data-placement="top" title="100%" /> */}
                                            </div>
                                        </div>

                                        <span className="progressText text-black"><b>Automotive Supplies</b></span>
                                        <div className="progress mt-probar-1 m-b15 m-t5">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>
                                                {/* <span className="popOver" data-toggle="tooltips" data-placement="top" title="100%" /> */}
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Electrical Equipment</b></span>
                                        <div className="progress mt-probar-1 m-b15 m-t5">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>
                                                {/* <span className="popOver" data-toggle="tooltips" data-placement="top" title="100%" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="modal fade" id="myModal2" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://vimeo.com/34741214' />
                        </div>
                    </div>
                </div> */}
            </>
        );
};

export default OurValue;