import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import { filtersData, productsData } from "../../data";
import axios from "axios";
import OurValue3 from "../Elements/OurValueD";
var bnrimg = require("./../../images/products/banner.jpg");
function ProductPage() {
  const products = productsData;
  const filters = filtersData;

  const [visibleItems, setVisibleItems] = useState(products.length);
  const [hasMore, setHasMore] = useState(true);

  const [categories, setCategories] = useState(null);
  const [productsD, setProducts] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("*"); // State for selected category
  const [about, setAbout] = useState({});
  const [filteredProducts, setfilteredProducts] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/products-list`
      );
      setProducts(response.data);
      console.log("Products  : ", response.data);
    };

    const fetchAbout = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/about_info`
      );
      setAbout(response.data);
      console.log("About Info : ", response.data);
    };
    const fetchCategories = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/categories`
      );
      setCategories(response.data);
      console.log("Categories : ", response.data);
    };
    fetchProducts();
    fetchCategories();
    fetchAbout();
  }, []);

  const truncateText = (text) => {
    const maxLength = 50;
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    if (visibleItems >= products.length) {
      setHasMore(false); // No more products to load
    }
  }, [visibleItems]); // This effect will trigger whenever visibleItems changes

  useEffect(() => {
    if (selectedCategory === "*") {
      setfilteredProducts(productsD); // Display all products
      console.log("All Products", productsD);
    } else {
      const filtered = productsD?.filter(
        (product) => product.product_category_id == selectedCategory
      );
      setfilteredProducts(filtered);
      console.log("Filtered Products", filtered);
    }
  }, [selectedCategory, productsD]); // Trigger re-filter when the category or products change

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 2); // Increase visible items by 2
  };
  console.log("visibleItems", visibleItems);

  const handleCategoryFilter = (categoryId) => {
    setSelectedCategory(categoryId); // Update selected category
    setVisibleItems(productsData.length); // Reset visible items
    console.log("ID : ", categoryId);
  };
  return (
    <div className="section-full p-tb80 bg-gray inner-page-padding">
      <div className="container">
        <div className="filter-wrap p-b30 text-center">
          <ul
            className=""
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              listStyle: "none",
              padding: "0",
              margin: "0",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            {categories && categories.length > 0 ? (
              <>
                <li className={selectedCategory === "*" ? "active" : ""}>
                  <li
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "16px",
                      // make it uppercase
                      textTransform: "uppercase",
                      color: selectedCategory === "*" ? "#0DA6C2" : "black",
                    }}
                  >
                    <span onClick={() => handleCategoryFilter("*")}>All</span>
                  </li>
                </li>
                {categories.map((item, index) => (
                  <li
                    key={index}
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      fontSize: "16px",
                      color: selectedCategory === item.id ? "#0DA6C2" : "black", // Red for active category
                    }}
                  >
                    <span onClick={() => handleCategoryFilter(item.id)}>
                      {item.name}
                    </span>
                  </li>
                ))}
              </>
            ) : (
              <div></div>
            )}
          </ul>
        </div>
        <div className=" mfp-gallery work-grid row clearfix">
          {filteredProducts && filteredProducts.length > 0
            ? filteredProducts.slice(0, visibleItems).map((item, index) => (
                <div
                  key={index}
                  className={`${item.product_category_id} masonry-item col-lg-3 col-md-3 col-sm-6 m-b30`}
                >
                  <div className="image-effect-one hover-shadow">
                    <img
                      style={{ height: "260px" }}
                      src={`${process.env.REACT_APP_API_URL}/storage/${item.banner}`}
                      alt=""
                    />
                    <div
                      className="figcaption"
                      style={{ padding: "10px !important" }}
                    >
                      <h4>{item.title}</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: truncateText(item.short_description),
                        }}
                      ></p>
                      <NavLink
                        to={`/products/${item.slug}`}
                        className={"text-primary button"}
                        style={{
                          display: "flex",
                          fontWeight: "bold",
                          justifyContent: "end",
                          padding: "10px",
                        }}
                      >
                        Read More ...
                      </NavLink>
                    </div>
                    <a
                      className="mfp-link"
                      href={`${process.env.REACT_APP_API_URL}/storage/${item.banner}`}
                    >
                      <i className="fa fa-arrows-alt" />
                    </a>
                  </div>
                </div>
              ))
            : products.map((item, index) => (
                <div
                  key={index}
                  className="col-md-4 col-sm-6"
                  style={{ padding: "2px" }}
                >
                  <div className="shimmer-effect">
                    <div className="shimmer shimmer-image" />
                    <div className="shimmer shimmer-text" />
                    <div
                      className="shimmer shimmer-text"
                      style={{ width: "80%" }}
                    />
                    <div className="shimmer shimmer-icons" />
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}

export default ProductPage;
