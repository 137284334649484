import React, { useState, useEffect } from 'react';
import Navigation from '../Common/Navigation';
import { NavLink } from 'react-router-dom';
import axios from 'axios'
var bnr = require('./../../images/background/bg-5.png');

const Header = () => {
    const [logo, setLogo] = useState(require('./../../images/logo/logo.png'));
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isQuoteActive, setIsQuoteActive] = useState(false);


    const [about, setAbout] = useState({})

    useEffect(() => {
        const fetchAbout = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/business-setup`);
            setAbout(response.data);
            console.log("Business Setup : ",response.data);
        };
        fetchAbout();
    }, []);

    const handleSearchToggle = () => {
        setIsSearchActive(!isSearchActive);
    };

    const handleQuoteToggle = () => {
        setIsQuoteActive(!isQuoteActive);
    };

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            const stickyheader = document.querySelector('.sticky-header');

            if (offset >= 100) {
                stickyheader.classList.add('is-fixed');
                stickyheader.classList.add('color-fill');
            } else {
                stickyheader.classList.remove('is-fixed');
                stickyheader.classList.remove('color-fill');
            }
        };

        window.addEventListener('scroll', handleScroll);

        window.updateTopMostParent = (logopath) => {
            setLogo(logopath);
        };

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <header className="site-header header-style-1">
                <div className="top-bar bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="mt-topbar-left clearfix">
                                <ul className="list-unstyled e-p-bx pull-right">
                                {
                                    about && about.email &&
                                    <li><i className="fa fa-envelope" /> {about.email}</li>
                                }
                                    <li><i className="fa fa-phone" /> 
                                     
                                {
                                    about && about.phone1 &&
                                       <>
                                       {about.phone1} / 
                                       </>
                                }

                                {
                                    about && about.phone2 &&
                                       <>
                                       {about.phone2} / 
                                       </>
                                }
                                +251 944 257 225 
                                </li>
                                </ul>
                            </div>
                            <div className="mt-topbar-right clearfix">
                                <div className="appint-btn">
                                    <NavLink to={"/contactus"} className="site-button">Contact Us</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-header main-bar-wraper">
                    <div className="main-bar bg-white">
                        <div className="container">
                            <div className="logo-header ">
                                <div className="logo-header-inner logo-header-one ">
                                    <NavLink to={"/"} style={{ display: 'flex' }}>
                                        <img src={logo} style={{ height: '60px' }} alt="Laffo Engineering" title="Laffo Engineering" />
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'end',
                                            flexDirection: 'column',
                                            fontSize: '16px',
                                        }}>
                                            <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Laffo</span>
                                            <span style={{ fontWeight: 'bold' }}>Engineering</span>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                            <button data-target=".header-nav" data-toggle="collapse" type="button" className="navbar-toggle collapsed">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>
                            <div className="extra-nav">
                                <div className="extra-cell">
                                    <NavLink to={"#"} className="contact-slide-show" onClick={handleQuoteToggle}>
                                        <i className="fa fa-angle-left arrow-animation" />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="contact-slide-hide" style={{ backgroundImage: `url(${bnr})`, right: isQuoteActive ? '0px' : '-500px' }}>
                                <div className="contact-nav">
                                    <NavLink to={"#"} className="contact_close" onClick={handleQuoteToggle}>×</NavLink>
                                    <div className="contact-nav-form p-a30">
                                        <div className="contact-info m-b30">
                                            <div className="mt-icon-box-wraper center p-b30">
                                                <div className="icon-xs m-b20 scale-in-center"><i className="fa fa-phone" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                    <p>+251 944 257 225 <br /> +251 116 395 250 <br /> +251 911 168 287</p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper center p-b30">
                                                <div className="icon-xs m-b20 scale-in-center"><i className="fa fa-envelope" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Email address</h5>
                                                    <p>info@laffoeng.com</p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper center p-b30">
                                                <div className="icon-xs m-b20 scale-in-center"><i className="fa fa-map-marker" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Address info</h5>
                                                    <p>
                                                        1. Alfoz Plaza Building, Addis Ababa <br />
                                                        2. Awi-Hakim Building, Addis Ababa
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="full-social-bg">
                                            <ul>
                                                <li><NavLink to={"#"} className="facebook"><i className="fa fa-facebook" /></NavLink></li>
                                                <li><NavLink to={"#"} className="google"><i className="fa fa-google" /></NavLink></li>
                                                <li><NavLink to={"#"} className="instagram"><i className="fa fa-instagram" /></NavLink></li>
                                                <li><NavLink to={"#"} className="tumblr"><i className="fa fa-tumblr" /></NavLink></li>
                                                <li><NavLink to={"#"} className="twitter"><i className="fa fa-twitter" /></NavLink></li>
                                                <li><NavLink to={"#"} className="youtube"><i className="fa fa-youtube" /></NavLink></li>
                                            </ul>
                                        </div>
                                        <div className="text-center">
                                            <h4 className="font-weight-600">Laffo Engineering</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="search" className={isSearchActive ? "open" : null}>
                                <span className="close" onClick={handleSearchToggle} />
                                <form role="search" id="searchform" action="/search" method="get" className="radius-xl">
                                    <div className="input-group">
                                        <input name="q" type="search" placeholder="Type to search" />
                                        <span className="input-group-btn">
                                            <button type="button" className="search-btn"><i className="fa fa-search arrow-animation" /></button>
                                        </span>
                                    </div>
                                </form>
                            </div>
                            <Navigation />
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
